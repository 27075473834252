<template>
  <nav>
    <v-toolbar flat>
      <v-toolbar-title
        @click="goToHome()"
        class="hover text-uppercase grey--text"
      >
        <v-avatar :tile="true">
          <v-img
            max-height="64"
            max-width="64"
            :src="require('@/assets/DictionaryLogo.svg')"
          ></v-img>
        </v-avatar>
        <span class="font-weight-light"> Karen </span>
        <span class="font-weight-bold">Ultimate</span>
        <span class="font-weight-light"> Dictionary </span>
      </v-toolbar-title>
      <v-spacer />

      <v-btn text @click="goToHome()" class="mx-2">
        <v-icon left>mdi-home</v-icon> Home
      </v-btn>

      <v-btn text @click="goToAbout()" class="mx-2">
        <v-icon left>mdi-information</v-icon>About
      </v-btn>

      <v-menu
        v-if="user"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar color="teal darken-3" size="40" v-bind="attrs" v-on="on">
            <span class="white--text text-h5">{{ oneWordAvatar }}</span>
          </v-avatar>
        </template>
        <v-card class="mx-auto" width="256">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="teal darken-3" size="36">
                  <span class="white--text text-h5">{{ oneWordAvatar }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.displayName }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :to="item.link"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <div class="pa-2">
            <v-btn block @click="logout" color="secondary">
              Logout<v-icon right> mdi-logout </v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-menu>

      <v-btn v-else color="primary" @click="login">
        Login <v-icon right> mdi-login </v-icon></v-btn
      >
    </v-toolbar>
  </nav>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
export default {
  data() {
    return {
      user: null,
      menu: false,
      items: [
        { title: "Account", icon: "mdi-account-box", link: "/account" },
        { title: "Favorite", icon: "mdi-heart", link: "/favourite" },
        { title: "Add New Word", icon: "mdi-plus", link: "/create" },
        { title: "My Words", icon: "mdi-pencil", link: "/mywords" },
      ],
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ name: "Home" });
    },
    goToAbout() {
      this.$router.push({ name: "About" });
    },
    login() {
      this.$router.replace({ name: "Login" });
    },
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.user = null;
          this.$router.replace({ name: "Home" });
        })
        .catch((error) => {
          // An error happened.
          console.log(error);
        });
    },
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.user = user;
      } else {
        // User is signed out
        // ...
      }
    });
  },
  computed: {
    oneWordAvatar() {
      if (this.user) {
        return this.user.displayName[0].toUpperCase();
      }
      return "A";
    },
  },
};
</script>

<style scoped>
.hover {
  cursor: pointer;
}
</style>
