<template>
  <v-container class="text-center">
    This app is aimed at the Karen people to learn English and Karen using this
    dictionary. Anyone can contribute by adding definitions themselves.
    <br /><br />

    This app is created by Frankie Benjamin <br />

    Special Thanks to တံကဲဘျုး for providing the Dictionary definitions.

    <footer class="text-center">
      <div class="centerflex">
        <a class="VPNavBarTitle" href="https://vuejs.org">
          <span class="text">Powered By</span>
          <svg
            class="logo"
            viewBox="0 0 128 128"
            width="24"
            height="24"
            data-v-5f26462c=""
          >
            <path
              fill="#42b883"
              d="M78.8,10L64,35.4L49.2,10H0l64,110l64-110C128,10,78.8,10,78.8,10z"
              data-v-5f26462c=""
            ></path>
            <path
              fill="#35495e"
              d="M78.8,10L64,35.4L49.2,10H25.6L64,76l38.4-66H78.8z"
              data-v-5f26462c=""
            ></path></svg
          ><span class="text">Vue.js</span></a
        >
      </div>
      App Version 1.0.0 ©2022 Karen Ultimate Dictionary
    </footer>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.VPNavBarTitle {
  display: flex;
  align-items: center;
  padding-top: 1px;
  height: var(--vt-nav-height);
  transition: opacity 0.25s;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.centerflex {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
