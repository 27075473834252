<template>
  <v-container v-if="user">
    <h1 class="text-center mb-3">Edit word</h1>

    <template>
      <v-row justify="center">
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="(item, i) in data.meaning" :key="i">
            <v-expansion-panel-header :disabled="item.user !== user.displayName"
              >{{ data.word }} - {{ item.type }} - {{ item.context }} - created
              by {{ item.user }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-card class="mx-auto my-12" max-width="600">
                <v-card-text>
                  <v-select
                    :disabled="item.user !== user.displayName"
                    :items="types"
                    label="Type"
                    v-model="item.type"
                  ></v-select>
                  <v-text-field
                    :disabled="item.user !== user.displayName"
                    label="Translation"
                    placeholder="Translation if any"
                    v-model="item.translation"
                  ></v-text-field>
                  <v-text-field
                    :disabled="item.user !== user.displayName"
                    label="Context"
                    placeholder="Where or when is the word used"
                    v-model="item.context"
                  ></v-text-field>
                  <v-textarea
                    :disabled="item.user !== user.displayName"
                    name="definition"
                    label="Definition"
                    v-model="item.definition"
                    value=""
                    hint="The meaning of the word..."
                  ></v-textarea>
                  <v-textarea
                    :disabled="item.user !== user.displayName"
                    name="example"
                    label="Example"
                    v-model="item.example"
                    value=""
                    hint="How is the word used in a sentence..."
                  ></v-textarea>
                  <v-text-field
                    :disabled="item.user !== user.displayName"
                    name="pronounceSound"
                    label="Pronounce Sound URL"
                    v-model="item.pronounceSound"
                    value=""
                    hint="How is the word used in a sentence..."
                  ></v-text-field>
                  <v-text-field
                    :disabled="item.user !== user.displayName"
                    name="phonetic"
                    label="Phonetic e.g.ˈdɪkʃ(ə)n(ə)ri"
                    v-model="item.phonetic"
                    value=""
                    hint="How is the word used in a sentence..."
                  ></v-text-field>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-actions>
                  <v-btn
                    :disabled="item.user !== user.displayName"
                    block
                    color="primary"
                    @click="updateWord(this)"
                  >
                    Update
                    <v-icon right> mdi-pencil </v-icon>
                  </v-btn>
                </v-card-actions>
                <div class="pa-4">
                  <a href="https://tanaqui-app.web.app/#/karen-font-converter"
                    >need help converting karen text to unicode?</a
                  >
                </div>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, Timestamp, getDoc, setDoc } from "firebase/firestore";
export default {
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.user = user;
      } else {
        // User is signed out
        this.$router.replace({ name: "Login" });
      }
    });
  },
  async mounted() {
    const auth = getAuth();
    const db = getFirestore();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        this.user = user;
        console.log(uid);
        const userDataRef = doc(db, "users", uid);
        const userDataSnap = await getDoc(userDataRef);

        if (userDataSnap.exists()) {
          console.log("User data:", userDataSnap.data());
          this.userData = userDataSnap.data();
        }
      }
    });

    const docRef = doc(db, "word", this.$route.params.word);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());

      this.data = docSnap.data();
      this.dataOriginal = docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },
  methods: {
    compareMeaning(a, b) {
      if (a.context !== b.context) return false;

      if (a.definition !== b.definition) return false;

      if (a.example !== b.example) return false;

      if (a.translation !== b.translation) return false;

      if (a.type !== b.type) return false;

      if (a.phonetic && b.phonetic) {
        if (a.phonetic !== b.phonetic) return false;
      }

      if (a.pronounceSound && b.pronounceSound) {
        if (a.pronounceSound !== b.pronounceSound) return false;
      }

      return true;
    },
    async updateWord() {
      const db = getFirestore();

      if (this.data) {
        this.dataOriginal.meaning.forEach((meaning, index) => {
          if (!this.compareMeaning(meaning, this.data.meaning[index])) {
            console.log("in if");
            this.data.meaning[index].updated = Timestamp.fromDate(new Date());
            this.data.meaning[index].updatedBy = this.user.displayName;
          }
        });

        console.log("Before updating");
        console.log(this.data);

        await setDoc(doc(db, "word", this.data.word), this.data).then(
          function () {
            alert("word updated");
          }
        );
      } else {
        console.log("No such document!");
      }
    },
  },
  data() {
    return {
      user: null,
      types: [
        "noun",
        "verb",
        "adjective",
        "preposition",
        "pronoun",
        "adverb",
        "conjunction",
        "interjection",
        "determiners",
      ],
      dataOriginal: null,
      data: null,
    };
  },
};
</script>
