<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  name: "App",
  components: { Navbar },

  data() {
    return {};
  },
};
</script>

<style>
@font-face {
  font-family: "Tanaqui Font";
  src: url(./fonts/Tanaqui-Font-Regular.ttf) format("truetype");
  unicode-range: U+1000-109F;
}

.tanaqui-font {
  font-family: "Tanaqui Font" !important ;
}
</style>
