<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card style="max-width: 600px; margin: auto">
          <v-card-text>
            <v-autocomplete
              v-model="model"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              hide-no-data
              hide-selected
              item-text="Description"
              item-value="Word"
              label="Search Dictionary"
              placeholder="Start typing to Search"
              prepend-icon="mdi-magnify"
              return-object
              v-on:keyup.enter="goTo()"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <span class="ml-5">{{ entries.length }} words in database</span>
            <v-spacer></v-spacer>
            <v-btn class="ma-5" @click="goTo()">
              Search
              <v-icon right> mdi-magnify </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <footer class="text-center pa-5">
      App Version 1.0.0 ©2022 Karen Ultimate Dictionary
    </footer>
  </v-container>
</template>

<script>
import { getFirestore } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";

export default {
  name: "Home",
  async mounted() {
    const db = getFirestore();

    const docRef = doc(db, "data", "wordList");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      this.count = docSnap.data().words.length;
      this.entries = docSnap.data().words.sort();
      this.isLoading = false;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },

  methods: {
    goTo() {
      if (this.model == null) {
        this.$router.push({
          name: "Word",
          params: { word: this.search.toLowerCase().trim() },
        });
      } else {
        this.$router.push({ name: "Word", params: { word: this.model } });
      }
    },
  },
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),

  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries;
    },
  },
  watch: {
    // whenever question changes, this function will run
    model: function () {
      console.log("ran");
      this.$router.push({
        name: "Word",
        params: { word: this.model.toLowerCase().trim() },
      });
    },
  },
};
</script>
