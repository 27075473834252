<template>
  <v-container>
    <div v-if="data">
      <v-card class="mx-auto" tile>
        <v-card-title>My Favorites</v-card-title>
        <v-card-text>
          <div v-if="data.favorites.length > 0">
            <div
              v-for="(word, index) in data.favorites"
              :key="index"
              class="my-1"
            >
              <router-link
                :to="{ name: 'Word', params: { word: word.word } }"
                >{{ word.word }}</router-link
              >
            </div>
          </div>
          <div v-else>
            <span>You have not liked any words</span>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { doc, getDoc } from "firebase/firestore";

export default {
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        this.user = user;
        console.log(uid);
        this.getUserDetails(uid);
      } else {
        // User is signed out
        // ...
        this.$router.replace({ name: "Login" });
      }
    });
  },
  methods: {
    async getUserDetails(uid) {
      const db = getFirestore();
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());

        this.data = docSnap.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        this.data = null;
      }
    },
  },
  data() {
    return {
      searchField: "",
      data: null,
      user: null,
    };
  },
};
</script>
