import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAOuh1HkJ79_jsgyrW8YJSlMdR79vPjXVs",
  authDomain: "karen-ultimate-dictionary.firebaseapp.com",
  projectId: "karen-ultimate-dictionary",
  storageBucket: "karen-ultimate-dictionary.appspot.com",
  messagingSenderId: "1056039648567",
  appId: "1:1056039648567:web:e84f89879a53b1c146aeb2",
  measurementId: "G-H16V8BH1MW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
