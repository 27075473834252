<template>
  <v-container>
    <v-card class="mx-auto my-12" max-width="500">
      <v-card-title>Register</v-card-title>

      <v-card-text>
        <v-text-field
          ref="username"
          v-model="username"
          :rules="[() => !!username || 'This field is required']"
          :error-messages="error"
          label="Username"
          placeholder="Username"
          required
        ></v-text-field>
        <v-text-field
          ref="email"
          v-model="email"
          :rules="[() => !!email || 'This field is required']"
          :error-messages="error"
          label="Email"
          placeholder="Email"
          required
        ></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Password"
          hint="At least 8 characters"
          counter
          @click:append="show1 = !show1"
        ></v-text-field>
        <router-link to="/login">already have an account?</router-link>
      </v-card-text>

      <v-card-actions>
        <v-btn block @click="submit">Create Account</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";

export default {
  methods: {
    async submit() {
      try {
        //const user =
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            const db = getFirestore();
            setDoc(doc(db, "users", userCredential.user.uid), {
              username: this.username,
              favorites: [],
              mywords: [],
            });
            updateProfile(auth.currentUser, {
              displayName: this.username,
            })
              .then(() => {
                const user = userCredential.user;
                console.log(user);

                this.$router.replace({ name: "Create" });
              })
              .catch((error) => {
                // An error occurred
                console.log(error);
              });
            const user = userCredential.user;
            console.log(user);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.log(errorCode + "\n MESSAGE: " + errorMessage);
            // ..
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
      username: "",
      show1: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
};
</script>
