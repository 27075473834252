<template>
  <div>
    <v-container>
      <v-btn @click="resetUsers">Reset Users</v-btn
      ><v-btn @click="resetWordList">Reset Word list</v-btn></v-container
    >
  </div>
</template>

<script>
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, setDoc, getDocs } from "firebase/firestore";
export default {
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.user = user;

        console.log(this.user);

        if (user.displayName !== "fkbcald") {
          this.$router.replace({ name: "Login" });
        }
      } else {
        // User is signed out
        this.$router.replace({ name: "Login" });
      }
    });
  },
  methods: {
    async resetWordList() {
      const db = getFirestore();

      if (this.words.length == 0) {
        let self = this;

        //get words
        const words = collection(db, "word");
        const querySnapshot = await getDocs(words);
        querySnapshot.forEach((result) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(result.id, " => ", result.data());
          self.words.push(result.data());
          self.wordList.push(result.data().word);
        });
      }

      if (this.wordList.length > 0) {
        await setDoc(doc(db, "data", "wordList"), { words: this.wordList });
      }
    },
    async resetUsers() {
      this.users = [];
      this.words = [];

      const db = getFirestore();
      let self = this;

      //get words
      const words = collection(db, "word");
      const querySnapshot = await getDocs(words);

      querySnapshot.forEach((result) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(result.id, " => ", result.data());
        self.words.push(result.data());
      });

      //get users
      const users = collection(db, "users");
      const querySnapshotUsers = await getDocs(users);
      querySnapshotUsers.forEach((result) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(result.id, " => ", result.data());
        self.users.push({ uid: result.id, data: result.data() });

        let newData = {
          username: result.data().username,
          favorites: [],
          mywords: [],
        };

        //for each word

        self.words.forEach((word) => {
          word.meaning.forEach((meaning) => {
            if (meaning.user == newData.username) {
              newData.mywords.push(word.word);
            }
          });
        });

        setDoc(doc(db, "users", result.id), newData).then(function () {
          console.log("user " + result.id + " resetted");
        });
      });
    },
  },
  data() {
    return {
      user: null,
      users: [],
      words: [],
      wordList: [],
    };
  },
};
</script>
