<template>
  <v-container v-if="user">
    <h1 class="text-center">new word</h1>

    <v-card class="mx-auto my-12" max-width="600">
      <v-card-title>
        <v-text-field
          label="Word"
          placeholder="Word"
          v-model="word"
          outlined
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-select :items="types" label="Type" v-model="type"></v-select>
        <v-text-field
          label="Translation"
          placeholder="Translation if any"
          v-model="translation"
        ></v-text-field>
        <v-text-field
          label="Context"
          placeholder="Where or when is the word used"
          v-model="context"
        ></v-text-field>
        <v-textarea
          name="definition"
          label="Definition"
          v-model="definition"
          value=""
          hint="The meaning of the word..."
        ></v-textarea>
        <v-textarea
          name="example"
          label="Example"
          v-model="example"
          value=""
          hint="How is the word used in a sentence..."
        ></v-textarea>
        <v-btn
          block
          color="primary"
          @click="addWordBulk"
          v-if="user.displayName === 'fkbcald'"
        >
          Bulk Create
          <v-icon right> mdi-pencil </v-icon>
        </v-btn>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-actions>
        <v-btn block color="primary" @click="addWord">
          Create
          <v-icon right> mdi-pencil </v-icon>
        </v-btn>
      </v-card-actions>
      <div class="pa-4">
        <a href="https://tanaqui-app.web.app/#/karen-font-converter"
          >need help converting karen text to unicode?</a
        >
      </div>
    </v-card>
  </v-container>
</template>

<script>
import json from "@/assets/data-a-part1.json";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, Timestamp, getDoc } from "firebase/firestore";
import { updateDoc, arrayUnion } from "firebase/firestore";
export default {
  created() {
    console.log(json);
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.user = user;
      } else {
        // User is signed out
        this.$router.replace({ name: "Login" });
      }
    });
  },
  methods: {
    async addToMyWords() {
      let wordFixed = this.word.toLowerCase().trim();
      const db = getFirestore();
      const myWords = doc(db, "users", this.user.uid);
      // Atomically add a new region to the "regions" array field.
      await updateDoc(myWords, {
        mywords: arrayUnion(wordFixed),
      });
    },
    resetWord() {
      this.word = "";
      this.type = "noun";
      this.context = "";
      this.example = "";
      this.translation = "";
      this.definition = "";
      this.likes = 0;
      this.dislikes = 0;
    },
    async addWordBulk() {
      json.Sheet1.forEach(async (w) => {
        let tempWord = {
          word: w.word,
          type: w.type,
          context: w.context ? w.context : "",
          example: w.example ? w.example : "",
          translation: w.translation ? w.translation : "",
          definition: w.definition ? w.definition : "",
          likes: 0,
          dislikes: 0,
        };

        console.log(tempWord);

        let wordFixed = tempWord.word.toLowerCase().trim();

        const db = getFirestore();

        const docRef = doc(db, "word", wordFixed);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());

          const docData = docSnap.data();
          docData.meaning.push({
            user: this.user.displayName,
            updated: null,
            updatedBy: null,
            created: Timestamp.fromDate(new Date()),
            type: tempWord.type,
            context: tempWord.context,
            example: tempWord.example,
            likes: 0,
            dislikes: 0,
            translation: tempWord.translation,
            definition: tempWord.definition,
          });

          let self = this;

          await setDoc(doc(db, "word", wordFixed), docData).then(function () {
            console.log("word added");
            let wordFixed = tempWord.word.toLowerCase().trim();
            const db = getFirestore();
            const myWords = doc(db, "users", self.user.uid);
            // Atomically add a new region to the "regions" array field.
            updateDoc(myWords, {
              mywords: arrayUnion(wordFixed),
            });
          });

          const wordListRef = doc(db, "data", "wordList");

          // Atomically add a new region to the "regions" array field.
          await updateDoc(wordListRef, {
            words: arrayUnion(wordFixed),
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          const docData = {
            word: wordFixed,
            meaning: [
              {
                user: this.user.displayName,
                updated: null,
                updatedBy: null,
                created: Timestamp.fromDate(new Date()),
                type: tempWord.type,
                context: tempWord.context,
                example: tempWord.example,
                likes: 0,
                dislikes: 0,
                translation: tempWord.translation,
                definition: tempWord.definition,
              },
            ],
          };

          let self = this;

          await setDoc(doc(db, "word", wordFixed), docData).then(function () {
            console.log("word added");
            let wordFixed = tempWord.word.toLowerCase().trim();
            const db = getFirestore();
            const myWords = doc(db, "users", self.user.uid);
            // Atomically add a new region to the "regions" array field.
            updateDoc(myWords, {
              mywords: arrayUnion(wordFixed),
            });
          });
          const wordListRef = doc(db, "data", "wordList");

          // Atomically add a new region to the "regions" array field.
          await updateDoc(wordListRef, {
            words: arrayUnion(wordFixed),
          });
        }
      });
    },
    async addWord() {
      console.log(this.word);

      let wordFixed = this.word.toLowerCase().trim();

      const db = getFirestore();

      const docRef = doc(db, "word", wordFixed);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());

        const docData = docSnap.data();
        docData.meaning.push({
          user: this.user.displayName,
          updated: null,
          updatedBy: null,
          created: Timestamp.fromDate(new Date()),
          type: this.type,
          context: this.context,
          example: this.example,
          likes: 0,
          dislikes: 0,
          translation: this.translation,
          definition: this.definition,
        });

        let self = this;

        await setDoc(doc(db, "word", wordFixed), docData).then(function () {
          alert("word added");
          self.addToMyWords();
          self.resetWord();
        });

        const wordListRef = doc(db, "data", "wordList");

        // Atomically add a new region to the "regions" array field.
        await updateDoc(wordListRef, {
          words: arrayUnion(wordFixed),
        });
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        const docData = {
          word: wordFixed,
          meaning: [
            {
              user: this.user.displayName,
              updated: null,
              updatedBy: null,
              created: Timestamp.fromDate(new Date()),
              type: this.type,
              context: this.context,
              example: this.example,
              likes: 0,
              dislikes: 0,
              translation: this.translation,
              definition: this.definition,
            },
          ],
        };

        let self = this;
        await setDoc(doc(db, "word", wordFixed), docData).then(function () {
          alert("word added");
          self.addToMyWords();
          self.resetWord();
        });
        const wordListRef = doc(db, "data", "wordList");

        // Atomically add a new region to the "regions" array field.
        await updateDoc(wordListRef, {
          words: arrayUnion(wordFixed),
        });
      }
    },
  },
  data() {
    return {
      user: null,
      word: "",
      types: [
        "noun",
        "verb",
        "adjective",
        "preposition",
        "pronoun",
        "adverb",
        "conjunction",
        "interjection",
      ],
      type: "noun",
      context: "",
      example: "",
      translation: "",
      definition: "",
      likes: 0,
      dislikes: 0,
    };
  },
};
</script>
