<template>
  <v-container>
    <div v-if="user">
      <v-card class="mx-auto">
        <v-card-title>Account <v-icon>mdi-account-circle</v-icon></v-card-title>
        <v-card-text>
          <h2>Welcome, {{ user.displayName }}</h2>
          <div>
            <span>{{ user.email }}</span>
            <v-chip
              v-if="!user.emailVerified"
              small
              class="ma-2"
              color="red"
              text-color="white"
            >
              Unverified
            </v-chip>
            <v-chip v-else small class="ma-2" color="green" text-color="white">
              Verified
            </v-chip>
            <span style="font-size: 0.5rem"
              >verification will be implemented later</span
            >
          </div>
          <div>Role: Creator</div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { doc, getDoc } from "firebase/firestore";

export default {
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        this.user = user;
        console.log(user);
        this.getUserDetails(uid);
      } else {
        // User is signed out
        // ...
        this.$router.replace({ name: "Login" });
      }
    });
  },
  methods: {
    async getUserDetails(uid) {
      const db = getFirestore();
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());

        this.data = docSnap.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        this.data = null;
      }
    },
  },
  data() {
    return {
      searchField: "",
      data: null,
      user: null,
    };
  },
};
</script>
